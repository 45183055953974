<template>
    <v-card class="my-2">
        <v-card-text>
            <div class="d-flex">
                <div class="d-flex align-start">
                    <v-avatar size="40" color="error darken-2" rounded>
                        <div class="d-flex flex-column">
                            <v-icon size="25" class="white--text"
                                >mdi-home-export-outline</v-icon
                            >
                        </div>
                    </v-avatar>
                </div>
                <div class="mx-3" style="width: 100%">
                    <h3>
                        {{ $_.get(eviction, "caseDetails.case.caseTitle") }}
                    </h3>
                    <div class="d-flex mt-3">
                        <v-spacer></v-spacer>
                        <v-chip
                            small
                            class="text-uppercase grey--text text--darken-1"
                            >{{ eviction.status }}</v-chip
                        >
                    </div>
                    <v-divider class="my-2" color="grey"></v-divider>
                    <div class="d-flex">
                        <span class="d-inline" style="width: 120px"
                            >Full Name:</span
                        >
                        <span class="font-weight-bold">
                            {{ eviction.caseDetails.fullName }}
                        </span>
                    </div>
                    <div class="d-flex">
                        <span class="d-inline" style="width: 120px"
                            >Address:</span
                        >
                        <span class="font-weight-bold"
                            >{{ eviction.caseDetails.address1 }}
                        </span>
                    </div>

                    <v-divider class="my-2" color="grey"></v-divider>
                    <div class="mt-2">
                        <h4 class="mb-1">Dates</h4>
                        <template
                            v-for="({ icon, date, from, label }, index) in [
                                eviction.fileDate,
                            ]"
                        >
                            <div class="d-flex" :key="index">
                                <v-icon class="mr-3">{{ icon }}</v-icon>
                                <span
                                    class="d-inline mr-3"
                                    style="width: 120px"
                                    >{{ label }}</span
                                >
                                <h4>{{ date ? date : "" }}</h4>
                                <v-spacer></v-spacer>
                                <v-chip small>{{ from }}</v-chip>
                            </div>
                            <v-divider
                                class="my-2"
                                color="grey ligthen-2"
                                :key="`divider-${index}`"
                            ></v-divider>
                        </template>
                    </div>
                    <div class="mt-2">
                        <h4 class="mb-1">Details</h4>
                        <template
                            v-for="(
                                { icon, label, text, type }, index
                            ) in eviction.info"
                        >
                            <div class="d-flex" :key="index">
                                <v-icon class="mr-3">{{ icon }}</v-icon>
                                <v-chip
                                    label
                                    small
                                    class="mr-3"
                                    color="grey lighten-4"
                                    style="width: 160px"
                                    >{{ label }}</v-chip
                                >
                                <v-spacer></v-spacer>
                                <v-chip
                                    v-if="type === 'chip'"
                                    color="secondary"
                                    dark
                                    small
                                    class="text-uppercase font-weight-bold"
                                    >{{ text }}</v-chip
                                >
                                <span v-else class="font-weight-bold">{{
                                    text
                                }}</span>
                            </div>
                            <v-divider
                                class="my-2"
                                color="grey ligthen-2"
                                :key="`divider-${index}`"
                            ></v-divider>
                        </template>
                    </div>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <div class="mx-3">
                <span
                    class="grey-text text--lighten-1 font-weight-light text-caption d-block"
                    >Source: {{ eviction.caseDetails.source }}</span
                >
                <span
                    class="grey-text text--lighten-1 font-weight-light text-caption d-block"
                    >Import Date: {{ eviction.caseDetails.importDate }}</span
                >
            </div>
            <v-spacer></v-spacer>
            <v-btn v-if="mode === 'screen'" small color="secondary" class="mr-3"
                >More Details</v-btn
            >
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "screen-eviction",
    components: {},
    props: {
        eviction: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>